@import "../../styles/variables.scss";

.wrapper {
  background-color: #fefffe;
  border-radius: $border-radius-lg;
  padding: $space-md $space-md;
  display: flex;
  justify-content: space-between;
  gap: $space-md;
}
