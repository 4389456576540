@import "../../../styles/variables.scss";

.events-layout {
  width: 100%;
  height: 100%;
  display: flex;
}

.sub-nav {
  width: 270px;
  min-width: 270px;

  height: 100%;
  background-color: $secondary-color;

  transform: translateX(-200px);
  opacity: 0;

  z-index: 49;
  animation: slide-in-fade-in $transition-medium ease-in-out forwards normal;
}

.content {
  flex: 1;
}

@keyframes slide-in-fade-in {
  from {
    transform: translateX(-200px);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
