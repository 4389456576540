@import "../../styles/variables.scss";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    color: #27aae1;
    margin: 0 0 $space-sm 0;
    font-size: $font-size-base;
  }

  .value {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $space-sm;

    color: $secondary-color;
  }
}
