@import "../../../styles/mixin.scss";
@import "../../../styles/variables.scss";

.wrapper {
  width: 100%;
  min-width: 500px;
  height: 70dvh;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-lg;

  .cropper-container {
    width: 100%;
    height: 70%;

    .cropper {
      position: relative;
      width: 100%;
      height: 80%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      overflow: hidden;
    }

    .controls {
      margin: 0.5rem 0 0 1rem;
      display: flex;
      flex-direction: column;
      gap: $space-sm;

      .zoom-slider {
        display: flex;
        align-items: center;
        gap: $space-sm;
      }

      .drag-tips {
        display: flex;
        align-items: center;
        gap: $space-sm;

        span {
          font-size: $font-size-small;
        }
      }
    }
  }

  @include button-group(flex-end, $space-md);
  .action-buttons-flex-end {
    width: 100%;
    padding: 0 $space-lg;
  }
}
