@import "variables.scss";

/* The following block can be included in a custom.scss */

$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color,
  "success": #28a745,
  "danger": #dc3545,
  "warning": #ffc107,
  "info": #17a2b8,
  "light": #f8f9fa,
  "dark": #343a40,
  "body": #212529,
  "muted": #6c757d,
  "white": #ffffff,
  "black": #000000,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.table {
  th {
    background-color: #c4cbd0;
    color: #5a5a5b;
    padding: 20px 10px;
    // border-right: 1px solid #5a5a5b;
  }

  td {
    background-color: #f5f5f5;
  }
}

// .btn:hover {
//   color: inherit;
//   background-color: inherit;
//   border-color: inherit;
// }

// .input-group {
//   width: auto;

//   button {
//     background-color: $primary-color;
//     color: $fourth-color;
//     border: none;
//   }
// }
