@import "../../styles/variables.scss";

.banner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;

  .banner-slider {
    width: 100%;
    height: 100%;

    display: flex;
    flex-wrap: nowrap;

    transition: transform $transition-medium ease-in-out;

    .banner-slide {
      width: 100%;
      height: 100%;
      position: relative;

      flex: 0 0 100%; /* Each slide takes up the full width */

      .banner-background {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .banner-title {
        position: absolute;
        top: 50px;
        left: 50px;
        color: #fefffe;
        font: $font-weight-extra-bold $font-size-large Poppins, sans-serif;
      }

      .learn-more-btn {
        position: absolute;
        bottom: 50px;
        right: 50px;
      }
    }
  }

  .dot-indicators {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: $space-lg;

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }

    .active-dot {
      background-color: $primary-color;
    }
  }
}
