@import "../../../styles/variables.scss";

.select-group {
  width: 100%;
  margin-bottom: $space-md;

  label {
    margin-bottom: $space-sm;

    .required {
      color: red;
    }
  }
}
