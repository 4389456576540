@import "../../../styles/variables.scss";

.heading {
  margin-bottom: $space-lg;

  .title {
    font-weight: $font-weight-bold;
  }

  .description {
    font-size: $font-size-base;
  }
}
