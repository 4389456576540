@import "../../../styles/variables.scss";

.nav {
  width: 170px;
  height: 100%;
  padding-bottom: $space-xl;
  // padding: 20px 35px;
  position: relative;

  background-color: $third-color;

  z-index: 50;

  .links-group {
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;

    border-radius: $border-radius-sm;
  }

  .other-links {
    padding: 0 $space-lg;
    margin-top: $space-lg;

    display: flex;
    flex-direction: column;
    gap: $space-md;

    a {
      display: flex;
      align-items: center;
      gap: $space-sm;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// @media (max-width: 991px) {
//   .nav {
//     display: none;
//   }
// }
