@import "../../../../styles/mixin.scss";
@import "../../../../styles/variables.scss";

.event-nav {
  padding: $space-md 0;
  margin-bottom: $space-lg;
  position: sticky;
  top: 0;

  display: flex;
  flex-direction: column;

  color: $fourth-color;

  .controls {
    padding: $space-md;

    display: flex;
    align-items: center;
    gap: $space-md;

    border-bottom: 1px solid $third-color;
  }

  .event-info {
    padding: $space-lg $space-md;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-md;

    border-bottom: 1px solid $third-color;
  }

  .form-steps {
    padding: $space-lg $space-md;

    display: flex;
    flex-direction: column;
    gap: $space-lg;
    list-style-type: none;

    .step {
      display: flex;
      align-items: center;
      gap: $space-sm;
    }

    .step-number {
      width: 20px;
      height: 20px;

      display: inline-flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      font-size: $font-size-small;
    }

    .step-title {
      font-weight: $font-weight-semi-bold;
    }

    .completed-step {
      .step-number {
        background-color: $primary-color;
        color: $fourth-color;
      }

      .step-title {
        color: $primary-color;
      }
    }

    .inactive-step {
      opacity: 0.3;

      .step-number {
        background-color: $fourth-color;
        color: $secondary-color;
      }
    }

    .active-step {
      .step-number {
        background-color: $fourth-color;
        color: $primary-color;
      }
    }
  }
}
