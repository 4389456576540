@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.address-input-form {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: $space-md;

  @include fade-in(0.5s);

  .map-wrapper {
    margin-top: $space-lg;

    display: flex;
    flex-direction: column;
    gap: $space-md;
  }
}

@include text-input;
@include select-input;

.input-row {
  display: flex;
  gap: $space-md;
}

@include button-group(flex-start, $space-md);
.action-buttons-flex-start {
  margin-top: $space-lg;
}
