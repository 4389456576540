@import "../../styles/variables.scss";

.greeting {
  margin-left: $space-xl;

  font-weight: $font-weight-bold;
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-md;

  .call-to-action {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    width: 100%;
    padding: $space-md $space-xl;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $secondary-color;

    & p {
      color: #fefffe;
      font-size: $font-size-large;
      font-weight: $font-weight-semi-bold;
    }
  }

  & > section:nth-child(2) {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    display: flex;
    flex-direction: column;
    gap: $space-md;
  }
}

// @media (max-width: 991px) {
//   .greeting {
//     margin-left: 10px;
//   }

//   .eventPrompt {
//     padding: 19px 20px;
//   }

//   .statsAndBanner {
//     flex-direction: column;
//   }
// }
