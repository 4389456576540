@import "../../styles/variables.scss";

.profile-stats {
  background-color: $secondary-color;
  height: 100%;
  padding: $space-lg;

  display: flex;
  flex-direction: column;

  color: #fefffe;

  .title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
  }

  .description {
    font-weight: $font-weight-semi-bold;
  }

  .stats-grids {
    margin-top: $space-md;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-lg $space-md;

    & div {
      &:nth-child(1) {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }

      &:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 2 / 3;
      }

      &:nth-child(3) {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
      }

      &:nth-child(4) {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
      }

      &:nth-child(5) {
        grid-row: 3 / 4;
        grid-column: 1 / 3;
      }

      &:nth-child(6) {
        grid-row: 4 / 5;
        grid-column: 1 / 3;
      }
    }

    .graph {
      color: #2a2c3a;
      background-color: #fefffe;
      border-radius: $border-radius-lg;

      .graph-title {
        font-size: 1.2rem;
        font-weight: $font-weight-semi-bold;
      }

      .graph-img {
        width: 100%;
        margin-top: $space-lg;
      }

      .x-axis {
        display: flex;
        justify-content: space-between;
        margin-top: $space-sm;
        font-size: $font-size-base;
      }
    }
  }
}
