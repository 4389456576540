@import "../../../styles/variables.scss";

.datetime-inputs {
  display: flex;
  flex-wrap: wrap;
  column-gap: $space-md;
  row-gap: $space-sm;

  .time-inputs {
    display: flex;
    column-gap: $space-md;
  }

  .picker-group p {
    display: block;
    margin-bottom: $space-sm;

    .required {
      color: red;
    }
  }
}

.add-datetime-btn {
  padding: 0;
  margin-top: $space-sm;

  display: flex;
  align-items: center;
  gap: $space-xs;

  font-weight: 500;
  color: $primary-color;
  background: none;
  border: none;
}

.timezone-select {
  margin-top: $space-md;
}
