@import "../../../styles/variables.scss";

.section-wrapper {
  display: flex;
  flex-direction: column;
  gap: $space-md;

  .option {
    display: flex;
    align-items: center;
    gap: $space-sm;

    cursor: pointer;

    .custom-checkbox {
      width: 20px;
      height: 20px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: $space-sm;

      border: 1px solid $primary-color;
      border-radius: $border-radius-sm;

      &.checked {
        background-color: $primary-color;
        color: $fourth-color;
      }
    }

    .option-checkbox {
      display: none;
    }

    .option-label {
      width: calc(100% - 20px);
    }
  }
}
