@import "../../../styles/variables.scss";

.container {
  min-height: 400px;
  padding: $space-lg $space-md;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $space-md;

  background-size: cover;
  background-position: center;
  transition: all $transition-fast ease;
  border: 1px dashed #000;
}

.image-requirements {
  margin-top: $space-md;

  font-size: $font-size-small;
  font-weight: $font-weight-bold;
}
