@import "../../../styles/variables.scss";

.input-group {
  flex: 1;

  label {
    margin-bottom: $space-sm;

    .required {
      color: red;
    }
  }
}
