@import "../../../styles/mixin.scss";

.content-wrapper {
  max-width: 1323px;
  width: 100%;

  display: flex;
  align-items: stretch;
  flex: 1;
}

.desktop-nav {
  @include mobile-screen {
    display: none;
  }
}

.outlet {
  width: 80%;
  max-width: 991px;
  // padding: 50px 30px;
  // display: flex;
  // flex-direction: column;
  // gap: 40px;

  flex: 1;
}

// @media (max-width: 991px) {
//   .desktop-nav {
//     display: none;
//   }
// }
