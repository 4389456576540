@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.social-media-container {
  width: clamp(360px, 100%, 600px);
  display: flex;
  flex-direction: column;
  gap: $space-md;

  .selected-social-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: $space-md;

    .selected-social {
      width: 100px;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $space-sm;

      @include fade-in($transition-medium);

      p {
        color: $primary-color;
        font-size: $font-size-small;
      }

      .selected-social-icons {
        width: 3rem;
        height: 3rem;

        background-color: $primary-color;
        color: $fourth-color;
        font-size: $font-size-large;
      }
    }
  }

  .accordion {
    position: relative;

    .accordion-container {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-direction: column;

      border: 1px solid $third-color;
      border-radius: $border-radius-sm;
      transition: all $transition-fast ease-in-out;
      z-index: 10;
    }

    .add-social-btn {
      width: 100%;
      padding: $space-md;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background-color: $fourth-color;

      .accordion-arrow {
        transition: transform $transition-fast ease-in-out;

        &.rotate-up {
          transform: rotate(180deg);
        }
      }
    }

    .social-input-list {
      width: 100%;
      padding: $space-sm;

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $space-sm;

      background-color: $fourth-color;
      z-index: 10;
    }
  }
}
