@import "../../../styles/variables.scss";

.preview-card {
  position: relative;
  width: 150px;
  height: 180px;
  padding: $space-sm 0;

  display: flex;
  justify-content: center;

  background-color: #d9d9d9;

  .preview-image {
    width: 90%;
    height: 80%;
    object-fit: contain;
  }
}

.remove-button {
  padding: 0;
  position: absolute;
  bottom: $space-xs;
  right: $space-xs;

  color: red;
  background: none;
  border: none;
}
