@import "../../styles/variables.scss";

.marketing-tips {
  padding: $space-lg $space-lg $space-xl $space-lg;

  display: flex;
  flex-direction: column;
  gap: $space-xl;

  background-color: $third-color;
}

.tip-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & div {
    h2 {
      margin-bottom: $space-sm;
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;
    }
  }

  .tip-icon {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
}

.tip-heading {
  p {
    font-size: $font-size-base;
    font-weight: $font-weight-semi-bold;
    margin-bottom: $space-lg;
  }
}
