@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.address-form {
  display: flex;
  flex-direction: column;
  gap: $space-md;

  .add-address-btn {
    padding: 0;
    margin-top: $space-xs;

    display: flex;
    align-items: center;
    gap: $space-xs;

    background: none;
    border: none;
  }

  .address-input-form {
    margin-top: $space-xs;
    display: flex;
    flex-direction: column;
    gap: $space-md;
  }
}

@include text-input;
@include select-input;
.input-row {
  display: flex;
  gap: $space-md;
}

@include button-group(flex-start, $space-md);
.action-buttons-flex-start {
  margin-top: $space-lg;
}
