@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $space-md;

  .video-section {
    display: flex;
    flex-direction: column;
    gap: $space-md;

    .video-list {
      display: flex;
      flex-direction: column;
      gap: $space-md;

      @include fade-in($transition-medium);
    }
  }

  .image-section {
    .image-list {
      padding: $space-md;

      display: flex;
      flex-wrap: wrap;
      gap: $space-sm;

      border: 1px dashed $secondary-color;

      @include fade-in($transition-medium);
    }
  }

  .media-actions {
    display: flex;
    justify-content: flex-end;
    gap: $space-md;

    .add-media-button {
      padding: $space-sm 0;

      display: flex;
      align-items: center;
      gap: $space-sm;

      background: none;
      border: none;

      .add-icon {
        width: 36px;
        height: 36px;

        color: $primary-color;
      }
    }
  }
}
