@import "../styles/variables.scss";

.error-content {
  width: 100%;
  height: 300px;
  padding: $space-xl;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-md;

  .heading {
    display: flex;
    align-items: center;
    gap: $space-xs;

    svg {
      width: 3rem;
      height: 3rem;

      opacity: 0.5;
    }
  }
}
