@import "../../../styles/variables.scss";

.section-wrapper {
  .heading {
    margin-bottom: $space-md;

    .title {
      display: flex;
      align-items: center;
      gap: $space-sm;

      .title-text {
        font-weight: $font-weight-bold;
      }
    }

    .description {
      font-size: $font-size-base;
    }
  }
}
