@import "../../../styles/variables.scss";

.title-sm {
  font-size: $font-size-large;
  font-weight: $font-weight-semi-bold;
}

.section-sm {
  padding: $space-lg $space-md;
}

.radio-buttons {
  display: flex;
  gap: $space-md;
}
