@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.input-group {
  width: 100%;
  height: 50px;
  position: relative;

  display: flex;
  align-items: center;

  border: 1px solid $secondary-color;
  border-radius: $border-radius-sm;

  @include fade-in(0.5s);

  .remove-btn {
    width: 20px;
    height: 18px;
    padding: 0;
    position: absolute;
    top: -9px;
    left: -10px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $secondary-color;
    color: $fourth-color;
    border-radius: $border-radius-sm;

    &:hover {
      background-color: red;
    }
  }

  .label {
    width: 150px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-sm;

    background-color: $third-color;
  }

  .input {
    height: 100%;
    padding: $space-sm;

    flex-grow: 1;

    border: none;
  }

  .add-btn {
    width: 100px;
    height: 100%;
    padding: 0;

    &.active {
      background-color: $primary-color;
      color: $fourth-color;
      font-size: $font-size-large;

      transition: opacity 0.3s ease;

      &:disabled {
        opacity: 0.3;
      }
    }

    &.linked {
      background-color: $third-color;
      color: $secondary-color;

      &:disabled {
        opacity: 0.3;
      }
    }
  }
}
