@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

@include button-group(flex-start, $space-md);
@include button-group(flex-end, $space-md);

.title {
  margin-bottom: $space-md;

  font-size: $space-md;
  font-weight: $font-weight-bold;
}

.address {
  margin: $space-xl 0;
}
