@import "../../../../styles/variables.scss";
@import "../../../../styles/mixin.scss";

.wrapper {
  padding: $space-md;
  position: relative;

  border: 1px dashed $secondary-color;
  border-radius: $border-radius-sm;
  background-color: $fourth-color;

  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  .remove-btn {
    width: 20px;
    height: 18px;
    padding: 0;
    position: absolute;
    top: -9px;
    left: -10px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $secondary-color;
    color: $fourth-color;
    border-radius: $border-radius-sm;

    &:hover {
      background-color: red;
    }
  }

  .content {
    display: flex;
    gap: $space-md;

    .thumbnail {
      width: 80px;
      height: 60px;
      object-fit: cover;
    }

    .title {
      font-weight: $font-weight-bold;
    }
  }
}
