@import "../../../styles/variables.scss";

.page-wrapper {
  width: 100%;
  padding: $space-xl $space-xl 200px $space-xl;

  display: flex;
  flex-direction: column;
  gap: $space-lg;
}
