@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

.section-wrapper {
  display: flex;
  flex-direction: column;
  gap: $space-md;
}

@include text-input;
@include select-input;

.input-row {
  display: flex;
  justify-content: space-between;
  gap: $space-md;
}
