@import "src/styles/variables.scss";

.container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap: $space-sm;

  .input-group {
    height: 50px;

    flex: 1;
    display: flex;
    align-items: center;
    gap: $space-sm;

    border: 1px solid $third-color;
    border-radius: $border-radius-sm;

    .label {
      width: 150px;
      height: 100%;
      padding-left: $space-md;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $space-sm;

      background-color: $third-color;

      .icon {
        width: 30px;
        height: 30px;

        background-color: $secondary-color;
        color: $fourth-color;
      }
    }

    .input {
      height: 100%;
      padding: 10px;

      flex-grow: 1;

      border: none;
    }
  }

  .add-btn {
    width: 50px;
    height: 100%;
    padding: 0;

    border-color: $third-color;
  }
}
