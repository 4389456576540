@import "../../../styles/variables.scss";
@import "../../../styles/mixin.scss";

@include text-input;
@include select-input;

.input-row {
  display: flex;
  justify-content: space-between;
  gap: $space-md;
}
